import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { ProductsComponent } from './products/products.component';
import { LayoutComponent } from './layout/layout.component';
import { LogoComponent } from './logo/logo.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home',  component: HomeComponent },
      { path: 'gallery',  component: GalleryComponent },
      { path: 'about',  component: AboutComponent },
      { path: 'contact',  component: ContactComponent },
      { path: 'services',  component: ServicesComponent },
      { path: 'products',  component: ProductsComponent }
    ]
  },
  { path: 'logo', component: LogoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
}), ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
