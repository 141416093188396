<div class="gallery" *ngIf="images">
  <div class="viewer">
    <div class="left" (click)="prev()" *ngIf="selectedImage > 0">
      <span class="ion-chevron-left"></span>
    </div>
    <div class="right" (click)="next()" *ngIf="selectedImage < images.length - 1">
      <span class="ion-chevron-right"></span>
    </div>
    <img [src]="images[selectedImage]" (click)="next()" class=""/>
  </div>
  <div *ngIf="images" class="images">
    <div class="image fadeIn" *ngFor="let image of images" [ngStyle]="{'background-image':'url(' + image + ')'}" (click)="onSelect(image)">
      <!--<img [src]="image" />-->
    </div>
  </div>
</div>