import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  public images: any[];
  public selectedImage: number;

  constructor(private _galleryService: GalleryService) { }

  ngOnInit() {

    this._galleryService.getMainGallery()
        .subscribe(data => this.handleImages(data));
  }

  handleImages(data){
    this.images = data;
    this.selectedImage = 0;
  }

  onSelect(image: string){
    this.selectedImage = this.images.indexOf(image);
  }

  next(){
    if(this.selectedImage < this.images.length - 1)
      this.selectedImage++;
  }
  prev(){
    this.selectedImage--;
  }

}
