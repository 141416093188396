<footer>
  <div>
    <div>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="/services">Services</a></li>
        <li><a routerLink="/products">Products</a></li>
        <li><a routerLink="/about">About</a></li>
        <li><a routerLink="/contact">Contact</a></li>
      </ul>
    </div>
    <div>
      <ul>
        <li><a routerLink="/services">Carpet Tiles</a></li>
        <li><a routerLink="/services">Carpet Nosings</a></li>
        <li><a routerLink="/services">Marmoleum</a></li>
        <li><a routerLink="/services">Safety Flooring</a></li>
        <li><a routerLink="/services">Vinyl Flooring</a></li>
        <li><a routerLink="/services">Whiterock Wall Cladding</a></li>
      </ul>
    </div>
    <div>
      <img src="/assets/images/altro_white.png" alt="altro whiterock premier installer"/>
    </div>
    <div>
      <p>
        <button routerLink="/contact">Get in Touch</button></p>
    </div>
  </div>

  <p>Copyright &copy; {{ year }} McKiernan Contract Flooring Limited. All rights reserved.</p>

</footer>
