import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class GalleryService {


  constructor() { }

  getHomeGallery(): Observable<any[]>{

    return of([
      {
        url: '/assets/images/gallery/01.jpg',
        focus: 'bottom-left'
      },
      {
        url: '/assets/images/gallery/02.jpg',
        focus: 'bottom'
      },
      {
        url: '/assets/images/gallery/commercial.jpg',
        focus: 'bottom-left'
      },
      {
        url: '/assets/images/gallery/retail.jpg',
        focus: 'bottom'
      },
      {
        url: '/assets/images/gallery/hospitality.jpg',
        focus: 'bottom-left'
      }
    ]);
  }

  getMainGallery(): Observable<string[]>{

    return of([
      '/assets/images/gallery/main/001.jpg',
      '/assets/images/gallery/main/002.jpg',
      '/assets/images/gallery/main/003.jpg',
      '/assets/images/gallery/main/004.jpg',
      '/assets/images/gallery/main/005.jpg',
      '/assets/images/gallery/main/006.jpg',
      '/assets/images/gallery/main/007.jpg',
      '/assets/images/gallery/main/008.jpg',
      '/assets/images/gallery/main/009.jpg',
      '/assets/images/gallery/main/010.jpg',
      '/assets/images/gallery/main/011.jpg',
      '/assets/images/gallery/main/012.jpg',
      '/assets/images/gallery/main/013.jpg',
      '/assets/images/gallery/main/014.jpg',
      '/assets/images/gallery/main/015.jpg',
      '/assets/images/gallery/main/016.jpg',
      '/assets/images/gallery/main/017.jpg',
      '/assets/images/gallery/main/018.jpg',
    ]);
  }

}
