<div class="feature">
  <div *ngIf="images" class="slideshow">
    <img *ngFor="let image of images; let i = index"
         alt="contract flooring altro forbo marmoleum uzin"
         [src]="image.url"
         [class]="image.focus"
         [class.fx]="current === i"
         [class.previous]="i === getPrevious()"/>
  </div>

  <!--
  <img class="hospital" src="/app/img/hospital.png" />
  <img class="school" src="/app/img/school.png" />
  <img class="office" src="/app/img/office.png" />-->
  <h1>Professional Flooring Solutions</h1>
  <h2><span class="title">Flooring solutions for:</span><span class="hospital" [class.active]="current === 0">Healthcare</span><span class="school" [class.active]="current === 1">Education</span><span class="office" [class.active]="current === 2">Commercial</span><span class="hotel" [class.active]="current === 3">Retail</span><span class="hospitality" [class.active]="current === 4">Hospitality</span></h2>
</div>
