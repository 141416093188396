import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ContactService } from '../contact.service';
declare var hbspt: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {

  contact = {
    name: '',
    email: '',
    phone: '',
    message: '',
    honeypot: '',
  };
  messageSent: boolean;

  constructor(private _contactService: ContactService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    hbspt.forms.create({
      region: "eu1",
      portalId: "143911569",
      formId: "b522611c-8d6f-41a9-8bef-f99f3eb06c14",
      target: "#hubspotForm"
    });

  }

  onSubmit(){
    // Anti-spam honeypot
    if (this.contact.honeypot) {
      return;
    }

    this._contactService.sendMessage(this.contact).subscribe(res => {
      this.messageSent = res;
    }, error => {
      console.log(error);
    });
  }

}
