<div class="container">
  <div class="logo">
    <img src="assets/logo/mckiernan-contract-flooring.svg" />
  </div>

  <div class="links">
    <h2>Vector Files</h2>
    <a download href="assets/logo/mckiernan-contract-flooring.svg">SVG</a>
    <a download href="assets/logo/mckiernan-contract-flooring.pdf">PDF</a>
    <h2>PNG <span>(Transparent Background)</span></h2>
    <a download href="assets/logo/mckiernan-contract-flooring-1600.png">1600px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-1280.png">1280px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-960.png">960px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-640.png">640px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-480.png">480px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-320.png">320px</a>
    <h2>JPG <span>(White Background)</span></h2>
    <a download href="assets/logo/mckiernan-contract-flooring-1600.jpg">1600px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-1280.jpg">1280px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-960.jpg">960px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-640.jpg">640px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-480.jpg">480px</a>
    <a download href="assets/logo/mckiernan-contract-flooring-320.jpg">320px</a>
  </div>
</div>
