import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shout',
  templateUrl: './shout.component.html',
  styleUrls: ['./shout.component.css']
})
export class ShoutComponent implements OnInit {

  @Input() title: string;
  @Input() text: string;
  @Input() shout: string;

  constructor() { }

  ngOnInit() {
  }

}
