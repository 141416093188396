<header [class.minimize]="minimize">
  <img src="/assets/images/logo.svg" alt="mckiernan contract flooring cavan" (click)="doSomething($event)" />
  <a (click)="showMenu = !showMenu" class="nav-show"><span [class.ion-android-menu]="!showMenu" [class.ion-close]="showMenu" ></span></a>
  <nav [class.show]="showMenu">
    <a routerLink="/" (click)="showMenu = false">Home</a><!--
        --><a routerLink="services" (click)="showMenu = false">Services</a><!--
        --><a routerLink="/products" (click)="showMenu = false">Products</a><!--
        --><a routerLink="/gallery" (click)="showMenu = false">Gallery</a><!--
        --><a routerLink="/about" (click)="showMenu = false">About</a><!--
        --><a routerLink="/contact" (click)="showMenu = false">Contact</a>
  </nav>
</header>
