<app-shout
        title="Services"
        text="We offer a variety of options including Safety Flooring, Marmoleum, Vinyl Flooring, LVT Flooring, Whiterock Cladding and Carpet Tiles."
        shout="Expect nothing less than<span>a five star service</span>">
</app-shout>
<div class="content">
  <div class="focus">
    <h2>Quality Products</h2>
    <p>At McKiernan Contract Flooring Ltd we believe in only supplying cost-effective, high quality products.  This is to guarantee your project is a long-term investment.  Our products will stand the test of time.  We have a wide variety of top of the range products for you to choose from, in various colours and on-trend designs.  We will work closely with you through every step, giving you professional guidance on supplying and fitting our products.  If your budget is tight, our products will also bring a new and impressive look to your area without the cost of a complete renovation.  Our products are also durable and easy to clean, delivering optimal value in the long run.</p>

    <h2>Quality Craftmanship</h2>
    <p>We provide the quality craftsmanship that must accompany quality flooring products.  As you likely know, you can spend money on superior flooring products, but if it is not fitted by a quality craftsman you will be disappointed. It is the quality of our work, our need for perfection, and our attention to detail that will not disappoint.</p>
    <p>As a testament to our quality work we have been selected as an Altro Premier Installer.  Altro only selects professionals with a high level of skill, superior expertise and strong commitment to customer care. Because of this we are proud to have joined the elite team of Premier Installers and look forward to installing their products throughout the region.  Altro also provides continuous training and professional development to allow us to stay up to date with the industries best practices and flooring solutions.</p>

    <h2>Quality Customer Service</h2>
    <p>We pride ourselves in providing an excellent standard of customer service.  Our team is friendly, respectful, professional and most importantly, dedicated to delivering projects on time.  We deliver client-specific solutions for outstanding performance and service excellence. Before, we will provide on-site assessments and consultancy services.  We will offer support on pricing, specifications, design and value, helping you pick the best options for long-term, cost effective results.  During, we will work diligently to complete the job as efficiently as possible.  If we are working in an area where we will be interacting with your customers, we assure you they will receive the same superior friendliness and respect at all times.  After installation, we will still be there to help you if you have any questions.  You can contact us via email or phone.</p>

    <h2>Quality Timing</h2>
    <p>We are known within the flooring industry for being reliable, dependable and hardworking.  We work efficiently with builders and contractors to keep jobs moving forward smoothly. If unforeseen events arise, we are flexible and take proactive steps to minimise delays.  By choosing us, you can guarantee your job will get completed as quickly as possible.</p>

    <h2>Quality Finish</h2>
    <p>We believe that the finished floor surface is only as good as the floor beneath it. Therefore, over the last 10 years we have built up an extensive knowledge of sub-floor preparation products. Combining this knowledge with skill, we pride ourselves with delivering a flawless floor every time.</p>

    <app-service-list></app-service-list>
  </div>
</div>