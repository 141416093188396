import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public minimize: boolean;
  public showMenu: boolean;

  constructor() { }

  ngOnInit() {

  }


  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if( document.body.scrollTop > 101)
      this.minimize = true;
    else
      this.minimize = false;
  }

}
