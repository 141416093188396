<app-shout
        title="About"
        text="Quality craftsmanship, product excellence, optimal value, and long-lasting solutions."
        shout="<span>Over 10 years experience</span> in the flooring industry">
</app-shout>
<div class="content">
  <div>
    <h2>Our Story</h2>
    <p>McKiernan Contract Flooring Ltd is a leading company in the supply and fitting of high quality, commercial flooring, serving the greater Midlands and Dublin region.  Backed by over 10 years of experience and expertise in the industry, we provide a fully integrated solution to all your flooring needs.  Offering a wide variety of options including safety flooring, linoleum, marmoleum, vinyl, rubber, carpet tiles and wall cladding, we are confident we have you covered.</p>
    <p>Growing our business from the ground up in the midst of the recession guarantees we know the unique ways to meet the needs of a struggling economy.  We pride ourselves in translating an economic recession into a business opportunity.  We have grown from strength to strength by identifying and satisfying a market need for <strong>quality craftsmanship, product excellence, optimal value, and long-lasting solutions</strong>.  With an unwavering commitment to meeting these needs, we have provided a solution that differentiates us from our competitors.  We consistently meet and exceed our client expectations.  As a testament to our superior service, the majority of our business is from returning customers, who value and trust our service, and our new business is driven mainly by ‘word of mouth’ referrals and reputation.</p>
    <p>While still maintaining the personal touch of a small business, we have established a large client basis across commercial, education, healthcare, hospitality, residential and retail sectors.  From safety flooring in wet rooms and commercial kitchens, to durable carpets in high traffic areas such as schools and churches, to hygienic wall cladding in hospitals and retail areas, we have completed a large portfolio of jobs which we are proud to stand behind.</p>
    <p>We understand that each client and company has unique and individual needs.  Understanding those needs is our key to consistently deliver customer service excellence at every encounter.  We pride ourselves in listening to our customers, tailoring every quote to their unique specifications, and providing competitive prices for the highest quality products to achieve maximum value each and every time.   Equally important, we do extensive floor preparation before installation, with specialized diamond grinding techniques, to ensure the products we fit and supply will last for many years to come.  Consequently, we consider our flooring a long-term investment in your premises.</p>

  </div>
</div>