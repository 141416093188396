import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../../gallery.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

  public images: any[];
  public current: number;

  constructor(private _galleryService: GalleryService) {

    this.current = 0;
    setInterval(() => {
      this.advance();
    }, 6000);
  }

  ngOnInit() {

    this._galleryService.getHomeGallery()
        .subscribe(data => this.images = data);
  }

  advance(){
    this.current = this.current < this.images.length - 1 ? this.current + 1 : 0;
  }

  getPrevious(): number{
    return (this.current == 0 ? this.images.length : this.current - 1);
  }

}
