<app-feature></app-feature>
<div class="content">
  <div class="focus">
    <h2>Welcome</h2>
    <p>McKiernan Contract Flooring Ltd is a leading company in the supply and fitting of high quality, commercial flooring, based in Cavan and serving the greater Midlands and Dublin region. Backed by over 10 years of experience and expertise in the industry, we provide a fully integrated solution to all your flooring needs. Offering a wide variety of options including safety flooring, linoleum, marmoleum, vinyl, rubber, carpet tiles and wall cladding, we are confident we have you covered.</p>
    <h2>Services</h2>
    <h3>Supply & Fit service available for:</h3>

    <app-service-list></app-service-list>
  </div>
</div>