<app-shout
        title="Contact"
        text="We'd be happy to talk to you about any enquiry you might have. Please contact us on 0860738808 for futher information or use the contact form below to send us an email.">
</app-shout>
<div class="content">
  <div class="focus">
    <div class="split-horizontal">
      <div *ngIf="!messageSent">
        <h2>Email</h2>
        <div id="hubspotForm"></div>
<!--        <form (ngSubmit)="onSubmit()">-->
<!--          <label>Name</label>-->
<!--          <input [(ngModel)]="contact.name" required name="name" type="text" placeholder="Your full name" />-->
<!--          <label>Email</label>-->
<!--          <input [(ngModel)]="contact.email" required name="email" type="email" placeholder="Your email address" />-->
<!--          <label>Phone</label>-->
<!--          <input [(ngModel)]="contact.phone" required name="phone" type="text" placeholder="Your phone number" />-->
<!--          <label>Message</label>-->
<!--          <textarea [(ngModel)]="contact.message" required name="message" placeholder="Your message..."></textarea>-->
<!--          <div>-->
<!--            <label>Subject</label>-->
<!--            <input [(ngModel)]="contact.honeypot" name="subject" type="text" placeholder="Subject" autocomplete="off"/>-->
<!--          </div>-->
<!--          <button type="submit">Send Message</button>-->
<!--        </form>-->
      </div>
      <div *ngIf="messageSent" class="success">
        <h2>Thanks for your message.</h2>
        <img src="/assets/images/success.png" />
        <h2>We'll be in touch soon!</h2>
      </div>
      <div class="contact">
        <h2>Phone</h2>
        <p>You can also contact Conor directly on <strong class="number">086 073 8808</strong></p>
        <h2>Address</h2>
        <p>
          <span><strong>McKiernan Contract Flooring</strong></span>
          <span>Laurel View</span>
          <span>Kildorough</span>
          <span>Ballyjamesduff</span>
          <span>Co. Cavan</span>
        </p>
      </div>
    </div>

  </div>
</div>
