import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProductsComponent } from './products/products.component';
import { ServiceListComponent } from './service-list/service-list.component';
import { ServicesComponent } from './services/services.component';
import { ShoutComponent } from './shout/shout.component';
import { FeatureComponent } from './home/feature/feature.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TestComponent } from './test/test.component';
import { GalleryService } from './gallery.service';
import { ContactService } from './contact.service';
import { LayoutComponent } from './layout/layout.component';
import { LogoComponent } from './logo/logo.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    GalleryComponent,
    HomeComponent,
    NavigationComponent,
    ProductsComponent,
    ServiceListComponent,
    ServicesComponent,
    ShoutComponent,
    FeatureComponent,
    TestComponent,
    LayoutComponent,
    LogoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    GalleryService,
    ContactService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
