<app-shout
        title="Products"
        text="At McKiernan Contract Flooring, we pride ourselves on using high quality products to produce a quality service."
        shout="We only use the <span>best products available</span>">
</app-shout>
<div class="content">
  <div class="focus">

    <div class="logos">
      <img src="/assets/images/logos/altro.svg" alt="altro flooring" />
      <img src="/assets/images/logos/forbo.jpg" alt="forbo flooring" />
      <img src="/assets/images/logos/uzin.jpg" alt="uzin flooring" />
    </div>
    <h2>Wall Cladding</h2>
    <p>Wall surface cladding is a versatile, sustainable and hygienic solution to creating impressive and clean environments in your premises.  Being a superior alternative to tile, we recommend wall cladding if patient safety and/or kitchen sanitation are your main priorities.  We use a 3D modeling system to mould the wall cladding to the desired shape, creating fully sealed areas around fixtures and fittings and keeping joints to a minimum, to reduce the risk of infection.  It is aesthetically pleasing, water- and oil-resistant, easy to maintain and straightforward to clean.  In conjunction with a regular cleaning schedule, you will benefit from the long-term solutions this wall cladding offers.</p>
    <p>Our wall cladding is the perfect choice for food preparation, healthcare and heavy traffic areas.  It meets the strict HACCP regulations for optimal food safety.  It is hygienic and resistant to chemicals for optimal patient safety.  And it is robust and hardwearing for environmental safety.</p>
    <p><em>Types:</em></p>
    <p><u>Top Hygiene:</u> Altro Whiterock</p>
    <p class="left-indent">This is the only solution if infection control is your top priority.  Hospital acquired infections and food-borne illnesses are a major concern in today’s world.  Altro Whiterock will help you tackle these problems and protect your customers and patients from potentially life-threatening situations.  A best practice option for clinical areas such as operating rooms and commercial kitchens, it is impermeable to germs, durable and robust, and easy to clean, giving you the peace of mind everyday that you are protecting the people you serve.</p>
    <p><u>Wall Protection:</u> Altro Fortis Titanium</p>
    <p class="left-indent">This is a hygienic wall protection for non-clinical, high traffic areas such as hospital corridors, offices and dining areas.</p>

    <h2>Safety Flooring</h2>
    <p>Safety flooring is a safe, hygienic and comfortable flooring option for environments like busy kitchens, factories, wet areas, gyms or hospital corridors, where high standards of cleanliness are essential.  With a low slip risk, this flooring is your only option to maximize patient, employees, and customers’ safety.  Meeting the requirements for safety regulations, you can be confident this flooring will minimize your liability.  This flooring also reduces noise which is important in healthcare environments and busy retail areas. It is easy to clean, preventing oil and dirt build up, and durable for high traffic areas, reducing wear and tear.  If in the unlikely event a section of the flooring needs to be replaced, it can be easily taken out and replaced for a seamless finish.</p>
    <p>One of the greatest advantages to our safety flooring is the ease of its installation.  We can significantly reduce the amount of time it can take to complete your project, minimize disruption to your employees and allowing you to enjoy your new flooring as quickly as possible.</p>

    <p><em>Types:</em></p>
    <p><u>Heavy duty:</u> Altro Unity 25, Altro Classic 25, Altro Designer 25</p>
    <p class="left-indent">These products offer exceptional durability and slip resistance for high traffic areas in healthcare, schools, retail and industrial areas.  Backed by a 20 year product warranty this really is the best long-term investment for your premises.</p>

    <p><u>Multipurpose:</u> Altro Walkway, Altro XpressLay, Altro Suprema II, Altro Wood Safety</p>
    <p class="left-indent">These products offer many design, textural and colour options in conjunction with durability and slip resistance.  They are aesthetically pleasing and can create a high end modern feel to attract customers or a homely feeling for patients and residents.  The non-sparkle finish of Altro Suprema II is perfect to create a calming environment for dementia patients.  Altro XpressLay is also the world’s first adhesive-free safety flooring, significantly reducing the time needed to allow traditional adhesives to dry.</p>

    <p><u>Specialty:</u> Altro Stronghold 30, Altro Atlas 40, Altro Aquarius</p>
    <p class="left-indent">These products are uniquely designed to handle the demands of specific environments.  Altro Stronghold 30 is perfect for greasy kitchens, while Altro Atlas 40 is best for industrial premises with extra resistance to chemicals and wear and tear.  Altro Aquarius, our most popular safety flooring, is ideal for wet areas such as changing rooms, bathrooms and showers.  Even when bare foot your flooring will have a low slip risk, making it the perfect option in your residents, hospital or healthcare setting.</p>
    <p>Did you know….the risk of slipping on an Altro Stronghold 30 floor is less than 1 in a million! With odds like this how can you afford not to invest in it?</p>

    <h2>Hygienic Systems</h2>
    <p>For the ultimate hygiene solution, we offer an integrated flooring and walling option to keep your patients and customers safe.  From the floor to ceiling we can create surfaces, edges, joints and corners that are fully sealed and impermeable to germs and chemicals.  The joints are welded and flush to the surface, creating a tidy and clean finish.  The system is also easy to clean, robust and durable, making it an ideal option for any kitchen, hospital or industrial environment.</p>

    <h2>Carpet and Carpet Tiles</h2>
    <p>Carpet and carpet tiles offer comfort, texture and warmth to any indoor space.  The high tech products which we install are synonymous with high quality and resilience.  Ideal for waiting rooms, office spaces, school classrooms or hallways, our carpets will create that welcoming atmosphere for years to come.</p>

    <h2>Marmoleum</h2>
    <p>Marmoleum, a leading Forbo brand of linoleum, offers a high quality and durable floor covering option for high traffic areas such as schools, offices and retail premises.  Marmoleum is made of natural products, is easy to install and easy to maintain.  Due to its low maintenance it requires less chemicals for cleaning, saving you even more money in the long run.  Additionally, due to its natural properties, it reaches room temperate quickly, creating a warm and cosy environment.  It is soft and sound absorbing, reducing unnecessary noise.   Our click panel locking system allows for fast installation, minimizing disruption to your business.  Available in a variety of 23 colours, from calming neutrals to vibrant hues, and high-end striking designs, it allows you to create a sharp flooring finish.  We also offer marmoleum modular, a design tile featuring over 100 products of various styles and sizes, for the ultimate unique creation.</p>

    <h2>Vinyl</h2>
    <p>Vinyl is a highly practical, scratch resistant flooring surface that is durable and easy to maintain.  Our premium vinyl comes in a depth of colours and innovative designs that will transform any indoor area.  It can create a comfortable and luxury finish, even reflecting natural looks of real wood or stone.  With wood effect vinyl you can even feel the groves and knots of real wood, thanks to an embossed texture finish.  The low maintenance needs of this vinyl, coupled with its sound and heat insulating properties, make it a cost-effective and excellent option for any premise.</p>

  </div>
</div>
