import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactService {

  constructor(private http: HttpClient) { }

  sendMessage(message: any): Observable<any> {
    return this.http.post('https://api.angularmailbox.com/messages', {
      sender: message.name,
      from: message.email,
      to: 'mail@mckiernanflooring.ie',
      subject: 'McKiernanFlooring.ie Contact',
      message: message.message,
      data: {
        'Phone Number': message.phone
      }
    });
  }

}
